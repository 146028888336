body {
  margin: 0;
  font-family: "Lato", "Helvetica", "Sans-serif";
  overflow-x: hidden;
}

h2,
h3,
h4,
h5,
h6 {
  color: #3f3d4b;
  font-weight: 700 !important;
}

hr {
  width: 100px;
  margin-left: 0;
  margin-top: 2rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.diffusionImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
}

.landingContainer {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/background-layer-plant.9f3028ea.png);
  background-size: contain, cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: bottom right;
}

.landingContainerFarmers {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/background-layer.bfb9764b.png);
  background-size: contain, cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: bottom right;
}

.howitworks {
  z-index: 2;
  width: 100%;
  background-image: url(/static/media/background-layer-reverse.5a907f05.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  -webkit-transform: translate(0, 10px);
          transform: translate(0, 10px);
}

.benefits{
    
}

.blurb {
  background-color: #3f3d4b;
}

.diffusion-hackathon {
  background-color: #3f3d4b;
  -webkit-transform: translate(0, 20px);
          transform: translate(0, 20px);
}

.loader-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
}

.ocean-protocol-dashboard {
  text-align: center;
}

footer {
  background-color: #3f3d4b;
  -webkit-transform: translate(0, 20px);
          transform: translate(0, 20px);
  color: white;
}

.opening-blurb {
  padding-top: 6rem;
  padding-left: 4rem;
}

header h1 {
  font-size: 1.4rem;
  text-align: center;
  /* color: white; */
}

.Grid {
  z-index: 1000;
  width: 60vw;
  height: 40vh;
  margin: auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.Grid-item {
  width: 32%;
  height: 32%;
  margin: 0.5%;
}

.image-container {
  position: relative;
}

.image-block-text {
  font-size: 9px;
}

.interaction-button-container {
  /* background-color: rgba(10, 10, 10, 0.8); */
  background-color: rgba(250, 250, 250, 0.8);
  color: #727272;
  /* position: absolute; */
  /* top: 0;
  width: 100%;
  height: 100%; */
  /* transform: translate(0%, -100%);  */
}


.socials {
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.scrollable-content {
  height: 84%;
  overflow-y: scroll;
}

.information-button {
  margin: auto;
}

@media (max-width: 768px) {
  .Grid {
    z-index: 1000;
    width: 96vw;
    height: 96vw;
  }
  header {
    height: 20vh;
  }
  header h1 {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
}

#image-0 {
  -webkit-transform: translate(100px ,-10px);
          transform: translate(100px ,-10px);
}
#image-1 {
  -webkit-transform: translate(10px ,150px);
          transform: translate(10px ,150px);
}
#image-3 {
  -webkit-transform: translate(200px, 110px);
          transform: translate(200px, 110px);
}
#image-4 {
  -webkit-transform: translate(150px ,200px);
          transform: translate(150px ,200px);
}
#image-5 {
  -webkit-transform: translate(100px ,220px);
          transform: translate(100px ,220px);
}
#image-6 {
  -webkit-transform: translate(-60px ,-10px);
          transform: translate(-60px ,-10px);
}
#image-7 {
  -webkit-transform: translate(-100px ,170px);
          transform: translate(-100px ,170px);
}

.ocean-protocol-dashboard{
    height: 86vh;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#31E080), to(#26C884));
    background-image: linear-gradient(#31E080, #26C884);    
}
